import { useContext, useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { Html5Qrcode } from "html5-qrcode";
import "./style.css";
import qr from "./qr.svg";
import QrScan from "../../Components/QrScan";
import { UserContext } from "../../Context/user";
import { Rotate, Zoom } from "react-reveal";
import { getOperations, getUserSell } from "../../Api/Api";

export default function Main() {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [summ, setSumm] = useState(0);
  console.log(summ);
  useEffect(() => {
    if (user && user.ID) {
      getUserSell(user.ID).then((data) => {
        setSumm(
          data
            .filter((em) => em.operation.TypeOperation == "success")
            .reduce(
              (partialSum, em) => partialSum + Number(em.operation.CashSumm),
              0
            )
        );
      });
    }
  }, [user]);

  return (
    <div className="main">
      {open && (
        <QrScan setSumm={setSumm} open={open} setOpen={setOpen}></QrScan>
      )}

      <div className="summ">
        <p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.1006 8.004C21.0456 8 20.9846 8 20.9206 8H18.3956C16.3276 8 14.5586 9.628 14.5586 11.75C14.5586 13.872 16.3286 15.5 18.3956 15.5H20.9206C20.9846 15.5 21.0456 15.5 21.1026 15.496C21.5276 15.4704 21.9288 15.2911 22.2315 14.9916C22.5341 14.6921 22.7176 14.2927 22.7476 13.868C22.7516 13.808 22.7516 13.743 22.7516 13.683V9.817C22.7516 9.757 22.7516 9.692 22.7476 9.632C22.7176 9.20726 22.5341 8.80793 22.2315 8.50842C21.9288 8.2089 21.5276 8.02963 21.1026 8.004M18.1746 12.75C18.7066 12.75 19.1376 12.302 19.1376 11.75C19.1376 11.198 18.7066 10.75 18.1746 10.75C17.6416 10.75 17.2106 11.198 17.2106 11.75C17.2106 12.302 17.6416 12.75 18.1746 12.75Z"
              fill="white"
              fill-opacity="0.27"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.918 17C20.9526 16.9986 20.987 17.0054 21.0184 17.0198C21.0499 17.0342 21.0775 17.0558 21.099 17.0829C21.1206 17.11 21.1354 17.1418 21.1424 17.1757C21.1493 17.2096 21.1481 17.2446 21.139 17.278C20.939 17.99 20.62 18.598 20.109 19.108C19.36 19.858 18.411 20.189 17.239 20.347C16.099 20.5 14.644 20.5 12.806 20.5H10.694C8.856 20.5 7.4 20.5 6.261 20.347C5.089 20.189 4.14 19.857 3.391 19.109C2.643 18.36 2.311 17.411 2.153 16.239C2 15.099 2 13.644 2 11.806V11.694C2 9.856 2 8.4 2.153 7.26C2.311 6.088 2.643 5.139 3.391 4.39C4.14 3.642 5.089 3.31 6.261 3.152C7.401 3 8.856 3 10.694 3H12.806C14.644 3 16.1 3 17.239 3.153C18.411 3.311 19.36 3.643 20.109 4.391C20.62 4.903 20.939 5.51 21.139 6.222C21.1481 6.25537 21.1493 6.29042 21.1424 6.32432C21.1354 6.35822 21.1206 6.39 21.099 6.41708C21.0775 6.44417 21.0499 6.46579 21.0184 6.4802C20.987 6.4946 20.9526 6.50139 20.918 6.5H18.394C15.557 6.5 13.057 8.74 13.057 11.75C13.057 14.76 15.557 17 18.394 17H20.918ZM5.75 7C5.55109 7 5.36032 7.07902 5.21967 7.21967C5.07902 7.36032 5 7.55109 5 7.75C5 7.94891 5.07902 8.13968 5.21967 8.28033C5.36032 8.42098 5.55109 8.5 5.75 8.5H9.75C9.94891 8.5 10.1397 8.42098 10.2803 8.28033C10.421 8.13968 10.5 7.94891 10.5 7.75C10.5 7.55109 10.421 7.36032 10.2803 7.21967C10.1397 7.07902 9.94891 7 9.75 7H5.75Z"
              fill="white"
              fill-opacity="0.27"
            />
          </svg>
          Ваш кешбек
        </p>
        <div className="total">{summ.toFixed(2)} Руб</div>
      </div>

      <img src={qr} className="check" alt="" />

      <div className="button" onClick={() => setOpen(true)}>
        СКАНИРОВАТЬ ЧЕК <span class="flare"></span>
      </div>
      <svg
        className="arrow"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.1588 6.3085C11.5525 5.69618 12.4475 5.69618 12.8412 6.3085L19.1458 16.1157C19.6866 16.9569 18.7942 17.9832 17.886 17.5646L12.4194 15.0448C12.1538 14.9224 11.8478 14.9223 11.5822 15.0448L6.11369 17.565C5.20551 17.9836 4.31321 16.9572 4.85396 16.1161L11.1588 6.3085Z"
          fill="#F71A41"
        />
      </svg>
      <p className="getting">
        <b>Для получения кешбека</b> осканируйте QR-code на чеке
      </p>
    </div>
  );
}
