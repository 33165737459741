import { useEffect, useState } from "react";
import "./style.css";
import { Html5Qrcode } from "html5-qrcode";
import {
  addOperation,
  checkFn,
  checkFNS,
  getDataFromScan,
  getUserSell,
} from "../../Api/Api";
import { Store } from "react-notifications-component";
import { useContext } from "react";
import { UserContext } from "../../Context/user";
import Notice from "../../Func/Func";
import qrToFnsCompareDate from "../../Func/QrToFns";
export default function QrScan({ setSumm, open, setOpen }) {
  const [data, setData] = useState("");
  const [user, setUser] = useContext(UserContext);
  const [tryInput, setTryInput] = useState(false);
  const [inputForm, setInputForm] = useState({
    fn: "",
    fp: "",
    fd: "",
    date: "",
    summ: "",
  });

  function checkData(form) {
    checkFn(form.fn).then((status) => {
      if (status != "err") {
        let cashSumm = (
          (Number(form.summ) / 100) *
          status.SaleForClients
        ).toFixed(2);
        let agencySumm = (
          (Number(form.summ) / 100) *
          status.AgencySumm
        ).toFixed(2);
        checkFNS({
          fn: form.fn,
          fd: form.fd,
          fp: form.fp,
          date: form.date,
          time: new Date(),
          sum: form.summ.replaceAll(".", ""),
          cashSumm: cashSumm,
          idUser: user.ID,
          cash: status.SaleForClients,
          agen: status.AgencySumm,
          agencySumm: agencySumm,
          idShop: status.ID,
        }).then((data) => {
          if (data == 200) {
            Notice("Успешно", "Баллы зачислены", "success");
          }
          if (data == 201) {
            Notice("Успешно", "Чек находится на проверке", "success");
          }
          if (data == 400) {
            Notice("Ошибка", "Неверно указаны данные чека", "danger");
          }
          if (data == "err") {
            Notice("Ошибка", "Чек уже зарегистрирован", "danger");
          }
        });
      } else {
        Notice("Ошибка", "Чек не принадлежит к магазинам партнеров", "danger");
      }
    });
  }

  function checkFromInput() {
    for (let key in inputForm) {
      if (inputForm[key].length == 0) {
        Notice("Ошибка", "Заполнены не все поля", "danger");
        return;
      }
    }
    let arrDate = inputForm.date.split(" ");
    let checkM = Number(arrDate[0].split(".")[1]);
    let yearM = "20" + arrDate[0].split(".")[2];

    let todayY = new Date().getFullYear();
    let todayM = new Date().getMonth() + 1;

    if (yearM == todayY && checkM == todayM) {
      let formatDate =
        "20" +
        arrDate[0].split(".").reverse().join("-") +
        "T" +
        arrDate[1] +
        ":00";
      checkData({
        fn: inputForm.fn,
        fp: inputForm.fp,
        fd: inputForm.fd,
        date: formatDate,
        summ: inputForm.summ,
      });
    } else {
      Notice(
        "Ошибка",
        "Дата чека должна соответствовать текущему месяцу",
        "danger"
      );
    }
  }
  const handeChange = (e) => {
    setInputForm({ ...inputForm, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const config = { fps: 10, qrbox: { width: 200, height: 200 } };
    const html5QrCode = new Html5Qrcode("qrCodeContainer");
    const qrScanerStop = () => {
      if (html5QrCode && html5QrCode.isScanning) {
        html5QrCode
          .stop()
          .then(() => console.log("stop it"))
          .catch(() => console.log("error"));
      }
    };

    const qrCodeSuccess = (decodedText) => {
      //t=20240628T1808&s=999.00&fn=7380440700550382&i=1127&fp=2410962519&n=1

      let arrParams = decodedText.split("&");
      let fn;
      let fp;
      let sum;
      let date;
      let fd;
      arrParams.map((em) => {
        if (em.includes("t=")) {
          date = em.replace("t=", "");
        }
        if (em.includes("fn=")) {
          fn = em.replace("fn=", "");
        }
        if (em.includes("fp=")) {
          fp = em.replace("fp=", "");
        }
        if (em.includes("s=")) {
          sum = em.replace("s=", "");
        }
        if (em.includes("i=")) {
          fd = em.replace("i=", "");
        }
      });
      let todayY = new Date().getFullYear();
      let todayM = new Date().getMonth() + 1;
      if (date.slice(0, 4) == todayY && Number(date.slice(4, 6)) == todayM) {
        checkData({
          fn: fn,
          fp: fp,
          fd: fd,
          date: qrToFnsCompareDate(date),
          summ: sum,
        });
      } else {
        Notice(
          "Ошибка",
          "Дата чека должна соответствовать текущему месяцу",
          "danger"
        );
      }

      /* checkFn(fn).then((status) => {
        if (status != "err") {
          let cashSumm = ((Number(sum) / 100) * status.SaleForClients).toFixed(
            2
          );
          let agencySumm = ((Number(sum) / 100) * status.AgencySumm).toFixed(2);
          addOperation(
            sum,
            date,
            fn,
            fp,
            user.ID,
            cashSumm,
            agencySumm,
            status.SaleForClients,
            status.AgencySumm,
            status.ID,
            fd
          ).then((response) => {
            if (response != "err") {
              Notice("Успешно", "Qr отсканирован, баллы зачислены", "success");

              getUserSell(user.ID).then((data) => {
                setSumm(
                  data
                    .filter((em) => em.operation.TypeOperation == "success")
                    .reduce(
                      (partialSum, em) =>
                        partialSum + Number(em.operation.CashSumm),
                      0
                    )
                );
              });
            } else {
              Notice("Ошибка", "Qr уже отсканирован", "danger");
            }
          });
        } else {
          Notice(
            "Ошибка",
            "Чек не принадлежит к магазинам партнеров",
            "danger"
          );
        }
      }); */
      setOpen(false);
    };
    html5QrCode.start({ facingMode: "environment" }, config, qrCodeSuccess);

    return () => {
      qrScanerStop();
    };
  }, []);
  return (
    <div className="popQr">
      <div className="someInput">
        <div
          style={tryInput ? { maxHeight: "100vh" } : { maxHeight: 0 }}
          className="inpQrForm"
        >
          <input
            type="text"
            name="fn"
            onChange={handeChange}
            className="inputComp"
            placeholder="ФН"
          />
          <input
            type="text"
            name="fd"
            onChange={handeChange}
            className="inputComp"
            placeholder="ФД"
          />
          <input
            type="text"
            name="fp"
            onChange={handeChange}
            className="inputComp"
            placeholder="ФП"
          />
          <input
            type="text"
            name="summ"
            onChange={handeChange}
            className="inputComp"
            placeholder="Сумма"
          />
          <input
            type="text"
            name="date"
            onChange={handeChange}
            className="inputComp"
            placeholder="Дата и время(как указана в чеке)"
          />
        </div>

        <button
          onClick={() => (!tryInput ? setTryInput(true) : checkFromInput())}
          className="inputQr"
        >
          {tryInput ? "Проверить чек" : "Ввести вручную"}
        </button>

        <button
          onClick={() => (!tryInput ? setOpen(false) : setTryInput(false))}
          className="closeQr"
        >
          {tryInput ? "Назад" : "Закрыть"}
        </button>
      </div>
      <div id="qrCodeContainer"></div>
      <p>{data}</p>
    </div>
  );
}
