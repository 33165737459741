import { useContext, useEffect, useState } from "react";

import "./style.css";
import { routes } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { getOperations, getShop, getUsers, sellBalls } from "../../../Api/Api";
import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import { AdminContext } from "../../../Context/admin";
import formDate from "../../../Func/Time";
import usersTableCSV from "../../../Func/UserTable";
import Notice from "../../../Func/Func";

import fnsToJs from "../../../Func/FnsToJs";
import summ from "../../../Func/summ";
export default function AdminUsers() {
  const [users, setUsers] = useState([]);
  const [spendBegin, setSpendBegin] = useState("");
  const [spendEnd, setSpendEnd] = useState("");
  const [search, setSearch] = useState("");
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [open, setOpen] = useState(false);
  const [operations, setOperations] = useState([]);
  const [currentPartners, setCurrentPartners] = useState([]);
  useEffect(() => {
    getOperations().then((data) => {
      setOperations(data);
    });
  }, []);
  function SellOperations() {
    let operationArr = operations.filter((em) => {
      let date = new Date(fnsToJs(em.DateOperation));
      let beg = new Date(spendBegin);
      let end = new Date(spendEnd);
      return (
        date >= beg &&
        date <= end &&
        em.TypeOperation == "success" &&
        currentPartners.includes(em.IdShop)
      );
    });
    operationArr = [
      ...new Set(
        operationArr.map((em) => {
          return em.ID;
        })
      ),
    ]
      .toString()
      .replaceAll("[", "")
      .replaceAll("]", "")
      .replaceAll(",", "','");

    if (operationArr.length > 0) {
      sellBalls(operationArr).then((status) => {
        if (status == 200) {
          Notice("Списание", "Баллы списаны", "success");
        }
      });
      console.log(operationArr);
    } else {
      Notice(
        "Списание",
        "Не найдено операций по заданным параметрам",
        "danger"
      );
    }
    setOpen(false);
    setSpendBegin("");
    setSpendEnd("");
  }
  const [admin, setAdmin] = useContext(AdminContext);
  const Navigate = useNavigate();
  useEffect(() => {
    getUsers().then((data) => {
      setUsers(data);
    });
    getShop().then((data) => setpartners(data));
  }, []);
  const [partners, setpartners] = useState("");
  console.log(currentPartners);
  function completeArr() {
    let finalArr;
    finalArr = users.map((user) => {
      let operation = operations.filter((operation) => {
        let date = new Date(fnsToJs(operation.DateOperation));
        if (
          operation.IdUser == user.ID &&
          date >= new Date(beginDate) &&
          date <= new Date(endDate) &&
          operation.TypeOperation == "success"
        ) {
          return operation;
        }
      });
      console.log(operation);
      return {
        fio: user.FIO,
        phone: user.Phone.slice(0, 10) + " ****",
        lcUk: user.Uk,
        lcEl: user.FaceNumberElectr,
        lcGas: user.FaceNumberGas,
        lcWater: user.FaceNumberWater,
        summ:
          operation && operation.length > 0
            ? operation.reduce((a, b) => a + Number(summ(b.Summ)), 0).toFixed(0)
            : 0,
        summCash:
          operation && operation.length > 0
            ? operation.reduce((a, b) => a + Number(b.CashSumm), 0).toFixed(0)
            : 0,
        summAv:
          operation && operation.length > 0
            ? operation.reduce((a, b) => a + Number(b.AgencySumm), 0).toFixed(0)
            : 0,
        ukName: user.UkName,
      };
    });
    let nameCsv =
      formDate(beginDate) + "-" + formDate(endDate) + " Пользователи.csv";

    usersTableCSV(finalArr, nameCsv);
    Notice(
      "Отчеты",
      "Отчет по пользователям формируется, дождись окончания загрузки",
      "success"
    );
  }
  return (
    <div className="cont">
      {open && (
        <div
          className="choosePartners"
          id="pt"
          onClick={(e) => {
            e.target.id == "pt" && setOpen(false);
          }}
        >
          <div className="chsBody">
            <h4>Выберите партнеров</h4>
            <div className="adminChoosePart">
              {partners &&
                partners.length > 0 &&
                partners.map((em, index) => {
                  return (
                    <div key={"pt" + index}>
                      <button
                        className="curBtn"
                        style={
                          currentPartners.find((elem) => elem == em.ID)
                            ? {
                                color: "#fff",
                                background: "#f61a41",
                              }
                            : {}
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          if (currentPartners.find((elem) => elem == em.ID)) {
                            setCurrentPartners((currentPartners) =>
                              currentPartners.filter((item) => item !== em.ID)
                            );
                          } else {
                            setCurrentPartners([...currentPartners, em.ID]);
                          }
                        }}
                      >
                        {currentPartners.find((elem) => elem == em.ID) ? (
                          <svg
                            className="zooom"
                            width="25"
                            height="26"
                            viewBox="0 0 25 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              y="0.5"
                              width="25"
                              height="25"
                              rx="5"
                              fill="#F61A41"
                            />
                            <path
                              d="M6.25 12.5L10.75 17L19 9"
                              stroke="white"
                              stroke-width="3"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="zooom"
                            width="25"
                            height="26"
                            viewBox="0 0 25 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="1"
                              width="24"
                              height="24"
                              rx="4.5"
                              stroke="#F61A41"
                            />
                          </svg>
                        )}
                      </button>
                      <p>{em.Name}</p>
                    </div>
                  );
                })}
            </div>
            <div className="downloadOth" onClick={() => SellOperations()}>
              Списать баллы
            </div>
          </div>
        </div>
      )}
      <AdminMenu />
      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <div className="usersCont">
          <div className="spendBalls buttonsAndOther">
            <div className="currentDate">
              <p>От</p>
              <input
                type="date"
                name=""
                id=""
                onChange={(e) => setSpendBegin(e.target.value)}
                value={spendBegin}
              />
              <p>До</p>
              <input
                type="date"
                name=""
                id=""
                onChange={(e) => setSpendEnd(e.target.value)}
                value={spendEnd}
              />
            </div>
            <div
              className="downloadOth"
              onClick={() => {
                if (spendBegin.length > 0 && spendEnd.length > 0) {
                  setOpen(true);
                } else {
                  Notice("Ошибка", "Не выбраны даты", "danger");
                }
              }}
            >
              Списать баллы
            </div>
          </div>
          <div className="buttonsAndOther">
            <h4>Пользователи</h4>
            <input
              type="text"
              value={search}
              placeholder="Поиск.."
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="currentDate">
              <p>От</p>
              <input
                type="date"
                name=""
                id=""
                onChange={(e) => setBeginDate(e.target.value)}
                value={beginDate}
              />
              <p>До</p>
              <input
                type="date"
                name=""
                id=""
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </div>
            <div
              className="downloadOth"
              onClick={() => {
                if (beginDate.length > 0 && endDate.length > 0) {
                  completeArr();
                } else {
                  Notice(
                    "Ошибка",
                    "Не выбраны даты для формирования отчета",
                    "danger"
                  );
                }
              }}
            >
              Скачать отчет
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46967 12.0303C5.76256 12.3232 6.23744 12.3232 6.53033 12.0303L11.3033 7.25736C11.5962 6.96447 11.5962 6.48959 11.3033 6.1967C11.0104 5.90381 10.5355 5.90381 10.2426 6.1967L6 10.4393L1.75736 6.1967C1.46447 5.90381 0.989592 5.90381 0.696699 6.1967C0.403805 6.48959 0.403805 6.96447 0.696699 7.25736L5.46967 12.0303ZM5.25 0.5L5.25 11.5L6.75 11.5L6.75 0.5L5.25 0.5Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className="partnersTable">
            <div className="adminTableTitle rzd">
              <div className="ava littleColumn">ID</div>
              <div className="pName">ФИО</div>
              <div className="pRub littleColumnl">Телефон</div>
              <div className="pUser">Email</div>
              <div className="pTel littleColumnd">Дата рег.</div>
              <div className="pWeb">Город, Адресс</div>
              <div className="pAdress littleColumnl">Лицевой счет УК</div>
              <div className="pAdress littleColumnd">Пол</div>
              <div className="pGr">Пароль</div>
            </div>
            {users &&
              users.length > 0 &&
              users.map((em, index) => {
                {
                  if (
                    em.FIO.toLowerCase().includes(search.toLocaleLowerCase()) &&
                    JSON.parse(em.Adress)
                      .value.toLowerCase()
                      .includes(admin.City.toLowerCase())
                  ) {
                    return (
                      <div
                        className="admPcol rzd"
                        key={"partner" + index}
                        onClick={() =>
                          Navigate(routes.adminUserSellData + "?key=" + em.ID)
                        }
                      >
                        <div className="ava littleColumn">{em.ID}</div>
                        <div className="pName">{em.FIO}</div>
                        <div className="pRub littleColumnl">{em.Phone}</div>
                        <div className="pUser">{em.Email}</div>
                        <div className="pTel littleColumnd">
                          {formDate(em.DateRegistration)}
                        </div>
                        <div className="pWeb">
                          {JSON.parse(em.Adress).value}
                        </div>
                        <div className="pAdress littleColumnl">{em.Uk}</div>
                        <div className="pAdress littleColumnd">{em.Sex}</div>
                        <div className="pGr">{em.Password}</div>
                      </div>
                    );
                  }
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
