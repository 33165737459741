export default function qrToFnsCompareDate(date) {
  return (
    date.slice(0, 4) +
    "-" +
    date.slice(4, 6) +
    "-" +
    date.slice(6, 9) +
    date.slice(9, 11) +
    ":" +
    date.slice(11, 13) +
    ":00"
  );
}
